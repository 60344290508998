.InfoUsers {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 106px;
    margin-bottom: 104px;
}

.InfoUsers_areaInCard {
    max-width: 1416px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.InfoUsers_leftPart {
    display: flex;
    flex-direction: column;
    max-width: 642px;
    width: 100%;
    justify-content: center;
}

.InfoUsers_leftPart_title {
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.pinkColorText {
    color: #8AFF14
}

.GoldenColorText {
    color: #8AFF14;
}

.InfoUsers_leftPart_mainText {
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
}

.InfoUsers_leftPart_button_Hire_us {
    display: flex;
    width: 300px;
    height: 38px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid #8AFF14;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    cursor: pointer;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
}

.InfoUsers_leftPart_button_Hire_us:hover {
    background: #8AFF14;
    color: #292E37;
}

.InfoUsers_rightPart {
    display: flex;
    flex-direction: column;
    max-width: 837px;
    width: auto;
    align-items: center;
    position: relative;



}

.InfoUsers_rightPart_Card {
    z-index: 1;
    background: #292E37;
    max-width: 695px;
    display: flex;
    width: 100%;
    flex-shrink: 0;
    border: 1px solid #ABB2BF;
}

.InfoUsers_rightPart_Card:last-child {
    margin-top: 40px;
}

.InfoUsers_rightPart_inCard_img {
    max-width: 264px;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;

    align-items: center;
    justify-content: center;
}

.InfoUsers_rightPart_inCard_img img {
    max-width: 264px;
    width: 100%;
}

.InfoUsers_rightPart_inCard_dataUser {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
}

.InfoUsers_rightPart_inCard_name {
    color: #FFF;
    font-family: "Fira Code";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.InfoUsers_rightPart_inCard_shortDesc {
    color: #8AFF14;

    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.InfoUsers_rightPart_inCard_Experience,
.InfoUsers_rightPart_inCard_Stack {
    margin-top: 16px;
    color: #FFF;
    max-width: 383px;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.InfoUsers_rightPart_points1 {
    position: absolute;
    bottom: -72px;
    left: -72px;
    z-index: 0;
}

.InfoUsers_rightPart_points1 img,
.InfoUsers_rightPart_points2 img {
    z-index: 0;
}

.InfoUsers_rightPart_points2 {
    position: absolute;
    top: -72px;
    right: -72px;
    z-index: 0;
}
@media screen and (max-width: 1200px) {
    .InfoUsers_areaInCard {
        max-width: 1416px;
        width: -webkit-fill-available;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }
    .InfoUsers_leftPart{
        max-width: inherit;
        margin-bottom: 24px;
    }
}
@media screen and (max-width: 800px) {


    .InfoUsers_leftPart {
        max-width: inherit;
        margin-bottom: 20px;
    }

    .InfoUsers_leftPart_title {
        font-size: 24px;
    }

    .InfoUsers_leftPart_button_Hire_us {
        width: auto;
    }

    .InfoUsers_rightPart {
        max-width: -webkit-fill-available;
        width: -webkit-fill-available;

    }

    .InfoUsers_rightPart_Card {
        max-width: -webkit-fill-available;
        flex-direction: column-reverse;
        align-items: center;
    }

    .InfoUsers_rightPart_inCard_Experience,
    .InfoUsers_rightPart_inCard_Stack {
        margin-top: 12px;
        color: #FFF;
        max-width: -webkit-fill-available;
    }
    .InfoUsers_rightPart_inCard_dataUser{
        display: flex;
        align-items: start;
        flex-direction: column;
        width: -webkit-fill-available;
        padding: 16px;
        justify-content: center;
    }
}
