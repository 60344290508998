.whatYouGet_Area{
    max-width: 1413px;
    width: 100%;
    margin-top: 111px;
}
.whatYouGet_Area_title{
    display: flex;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-items: center;
    margin-bottom: 40px;
}
.pinkColor{
    color: #8AFF14;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.pinkLine{
    height: 1px;
    width: 32vw;
    background: #8AFF14;
    margin-left: 24px;
}
.WhatYouGet_table_area{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
    justify-content: space-between;

}
.whatYouGet_table{
    border: 1px solid #ABB2BF;
}
.whatYouGet_Area_table_title{
    padding: 8px;
    color:  #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    border-bottom: 1px solid #ABB2BF;
    align-self: stretch;
    line-height: normal;
}
.whatYouGet_table_desc{
    padding: 8px;
    align-self: stretch;
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 326px ;
}

@media screen and (max-width: 800px) {
    .whatYouGet_Area{
        margin-top: 48px;
    }
    .whatYouGet_Area_title{
        font-size: 24px;
        margin-bottom: 16px;
    }
    .WhatYouGet_table_area{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .whatYouGet_table{
        margin-bottom: 16px;
    }
}