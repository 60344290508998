.header {
    max-width: 1416px;
    width: 100%;
    height: 68px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
}

.header_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 37px; */
    overflow: hidden;
    border: 1px solid #ABB2BF;
    background: #161C25;
    padding: 8px;
}

.header_logo.svg {
    width: auto;
    /* height: 37px; */
}

.header_logo_text {
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header_rightPart {
    display: flex;
    align-self: center;
    justify-content: center;
}

.header_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 100px);
    gap: 16px;
}

.header_menu_item {
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #ABB2BF;
    cursor: pointer;
    flex-grow: 1;
}

.header_menu_item_sharp {
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #8AFF14;
}

.header_menu_item:hover>.header_menu_item_sharp {
    color: #8AFF14;
}

.header_menu_item:hover {
    color: #FFF;
}

.header_selectorLang {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.header_selector {
    background: #292E37;
    color: #ABB2BF;
    border: none;
}

.header_contactBtn {
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    display: flex;
    width: max-content;
    height: auto;
    padding: 8px 20px;
    margin-left: 12px;
    justify-content: center;
    align-items: center;
    border: 2px solid #8AFF14;
    cursor: pointer;
}

.header_contactBtn:hover {
    background: #8AFF14;
    color: #161C25;
}

.headerMob {
    display: none;
}

@media screen and (max-width: 800px) {
    .header {
        display: none;
    }

    .headerMob {
        display: flex;
        width: -webkit-fill-available;
        justify-content: space-between;
        padding: 0 12px;
        position: relative;
        position: fixed;
        top: 0;
        z-index: 99999;
        background: #292E37;
        height: 68px;
        align-items: center;
    }

    .header_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 4px;
        height: 32px;
    }

    .burgerMenuBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .mobileMenu {
        position: absolute;
        top: 68px;
        z-index: 99999;
        width: -webkit-fill-available;
        display: none;
        align-items: center;
        justify-content: center;
        background: #292E37;
        border-bottom: 1px solid #ABB2BF;
        left: 0;
    }

    .menuItemMob {
        cursor: pointer;
        color: #ABB2BF;
        font-family: "Fira Code";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 32px;
    }

    .menuItemMob:hover {
        color: white;
    }

    .header_menu_item_sharp {
        font-size: 26px;
    }

    .header_menu_logo_sharp {
        font-family: "Fira Code";
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #8AFF14;
    }
    .header_selectorLang {
        margin-bottom: 32px;

    }

    .header_selector {
        font-size: 26px;
    }

    .header_contactBtn {
        font-size: 17px;
        margin-bottom: 40px;
        padding: 12px;
    }

    .header_selectorLang {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-right: 0;
    }

    .itemsMenu {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .iconBurger {
        cursor: pointer;
    }
    .openBurger{
        display: flex;
    }
}