.Projects{
    max-width: 1413px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 53px;
}
.Projects_title{
    display: flex;
    flex-direction: column;
    margin-bottom: 104px;
}
.pinkColor{
    color: #8AFF14;
}
.Projects_title_main{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.Projects_title_desc{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
    margin-top: 8px;
}


@media screen and (max-width: 800px) {
    .Projects{
        margin-top: 100px;
        padding: 0 12px;
        width: -webkit-fill-available;
    }
    .Projects_title_main{
        font-size: 24px;
    }
    .Projects_title{
        margin-bottom: 48px;
    }

}