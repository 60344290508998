
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap');
#root {
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}
body{
    margin: 0;
    padding: 0;
    background: #292E37;
    display: flex;
    align-items: center;
    justify-content: center;
}
a{
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 800px) {
    #root{
        padding: 0;
        
    }
}