.Footer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid  #ABB2BF;
    padding: 20px;
    margin-top: 130px;
}
.Footer_area{
    display: flex;
    max-width: 1413px;
    width: 100%;
    justify-content: space-between;
}
.Footer_area_part{
    display: flex;
    flex-direction: column;
}
.Footer_area_part_logo{
    display: flex;
    flex-direction: row;
    color:  #FFF;
    font-family: "Fira Code";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-items: center;
}
.Footer_area_part_logo svg{
    margin-right: 8px;
}
.Footer_area_part_logo{
    margin-bottom: 18px;
}
.Footer_area_part_desc{
    max-width: 340px;
    flex-direction: row;
    color:#ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.goldColor{
    color: #8AFF14;
}
.Footer_area_part_titleText{
    color:  #FFF;
    text-align: right;
    font-family: "Fira Code";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.Footer_area_part_contacts{
    display: flex;
    align-items: center;
    margin-top: 13px;
    color:  #ABB2BF;
    font-family: "Fira Code";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: end;
}
.Footer_area_part_contacts svg{
    margin-right: 8px;
}
.Footer_Copyright{
    color: #ABB2BF;
    text-align: center;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.header_menu_item_sharp {
    font-size: 26px;
}

.header_menu_logo_sharp {
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #8AFF14;
}
.btnFreeConslt{
    margin-top: 0px;
    border: 2px solid #8AFF14;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 375px;
    cursor: pointer;
    padding: 12px 10px;
}

.btnFreeConslt:hover {
    background: #8AFF14;
    color: #292E37;
}
.header_logo {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 37px; */
    overflow: hidden;
    border: 1px solid #ABB2BF;
    background: #161C25;
    padding: 8px;
}
.header_logo_text {
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.header_selectorLang {
    margin-bottom: 32px;

}
.header_menu_item_sharp {
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #8AFF14;
}

.header_menu_item:hover>.header_menu_item_sharp {
    color: #8AFF14;
}

.header_menu_item:hover {
    color: #FFF;
}
@media screen and (max-width: 800px) {
    .Footer {
        width: -webkit-fill-available;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-top: 1px solid #ABB2BF;
        padding: 20px 12px;
        margin-top: 54px;
    }
    .Footer_area {
        display: flex;
        max-width: 1413px;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }
    .Footer_area_part {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 19px;
    }
    .Footer_area_part_titleText{
        font-size: 17px;
    }
    .Footer_area_part_contacts{
        margin-top: 8px;
    }
    .Footer_Copyright{
        color: #ABB2BF;
        width: 100%;
        text-align: start;
        font-family: "Fira Code";
        font-size: 17px;
        margin-top: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}