.firstScreen {
    width: 105%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 32.2vw;
    background: #03B06D;
}

.marqueeLine {
    display: flex;
    width: 105%;
    height: 110px;
    position: relative;
    background: #3F4654;
    overflow: hidden;

}
.areaCards{
    display: grid;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
    margin-bottom: 0px;
    justify-items: center;
}
.margqueArea {
    position: absolute;
    top: 0;
    height: 110px;
    display: flex;
    gap: 24px;
    padding: 24px 0px;
    
}

.marquueItem {
    color: #FFF;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Fira Code";
    font-size: clamp(24px ,3vw, 47px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 40px;
}





.imageArea {
    width: 110%;
}

.imageArea img {
    width: 105%;
    margin-left: -24px;
}

.textArea {
    position: absolute;
    display: flex;
    flex-direction: row;
    padding: 24px;
}

.leftPartArea {
    max-width: 1355px;
}

h1 {
    color: #FFF;
    font-family: "Fira Code";
    font-size: clamp(22px, 5vw, 79px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.blackColor {
    color: #292E37;
}

h3 {
    color: rgba(255, 255, 255, 0.82);
    font-family: "Fira Code";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 32px;
    max-width: 668px;
}

.btnFreeConslt {
    margin-top: 40px;
    border: 2px solid #8AFF14;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 375px;
    cursor: pointer;
    padding: 12px 10px;
}

.btnFreeConslt:hover {
    background: #8AFF14;
}

.rightPartArea {
    max-width: 300px;
    width: 100%;
}

.itemSelector {
    margin-bottom: 16px;
    color: #FFF;
    text-align: right;
    font-family: "Fira Code";
    font-size: clamp(23px, 5vw, 40px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.itemSelector:hover {
    color: #8AFF14;
}

.secondScreen {
    margin-top: 96px;
    max-width: 1416px;
    width: 100%;
    margin-bottom: 48px;
}

.thirdScreen {
    max-width: 1416px;
    width: 100%;
    margin-bottom: 54px;
}

h2 {
    width: 100%;
    text-align: start;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 40px;
}

.pinkColor {
    color: #8AFF14;
}

.tableText {
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #ABB2BF;
    width: 100%;
}

.simpleText p {
    padding: 0 16px;

}

.topBlock {
    border-left: 1px solid #ABB2BF;
    border-top: 1px solid #ABB2BF;
    border-right: 1px solid #ABB2BF;
    max-height: 22px;
    height: 100%;
    width: 100%;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}

.topBlock span {
    padding: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
}

.areaThirditems {
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;

}

.rightPartThird {
    max-width: 994px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.leftPartThird {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.itemThird {
    margin-bottom: 16px;
    color: #FFF;
    text-align: left;
    font-family: "Fira Code";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.pinkColor {
    color: #8AFF14;
}

.fourthScreen {
    max-width: 1416px;
    width: 100%;
    margin-bottom: 48px;
    margin-top: 48px;
}

.areaBlocks {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
    justify-content: space-between;
}

.itemBlockFourth {
    max-width: 342px;
    width: 100%;
}

.btnFreeConsltMob {
    display: none;
}

@media screen and (max-width: 996px) {}

@media screen and (max-width: 800px) {
    .firstScreen {
        margin-top: 68px;
        height: 345px;
    }

    .textArea {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        align-items: center;
    }

    h1 {
        /* font-size: 22px; */
        line-height: normal;
    }

    h3 {
        font-size: 17px;
    }

    .btnFreeConslt {
        display: none;
    }

    .btnFreeConsltMob {
        margin-top: 40px;
        border: 2px solid #8AFF14;
        color: #FFF;
        font-family: "Fira Code";
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 375px;
        cursor: pointer;
        padding: 12px 10px;
        text-align: center;
    }

    .imageArea {
        width: inherit;
        height: 100%;
    }

    .imageArea img {
        width: inherit;
        height: 100%;
        margin-left: 0;
    }

    .itemSelector {
        /* font-size: 23px; */
        margin-bottom: 8px;

    }

    .rightPartArea {
        margin-top: 70px;
        max-width: none;
    }

    .secondScreen {
        margin-top: 54px;
        max-width: 1416px;
        width: -webkit-fill-available;
        padding: 0 12px;
        margin-bottom: 48px;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 30px;
    }

    .thirdScreen {
        padding: 0 12px;
        width: -webkit-fill-available;
        margin-bottom: 54px;
    }

    .areaThirditems {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        flex-direction: column;
    }

    .itemThird {
        font-size: 23px;
        margin-bottom: 8px;
    }

    .rightPartThird {
        margin-top: 24px;
    }

    .fourthScreen {
        padding: 0 12px;
        width: -webkit-fill-available;
        margin-bottom: 48px;
    }

    .areaBlocks {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

    }

    .itemBlockFourth {
        max-width: none;
        width: -webkit-fill-available;
        margin-bottom: 16px;
    }
    .marquueItem{
        font-size: 20px;
    }
    .marqueeLine{
        height: 58px;
    }
    .areaCards{
        justify-items: center;
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
        margin-bottom: 0px;
    }
}