.Skills{
    display: flex;
    max-width: 1413px;
    width: 100%;
    display: flex;
    margin-top: 152px;
    flex-direction: column;
}
.Skills_title{
    display: flex;
    align-items: center;
}
.Skills_title_text{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.pinkColor{
    color: #8AFF14;
}
.Skills_item{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.linePink{
    height: 1px;
    width: 32vw;
    background: #8AFF14;
    margin-left: 24px;
}

.Skills_item_tables {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}
.Skills_item_item_table{
    display: flex;
    flex-direction: column;
    border: 1px solid #ABB2BF;
    height: max-content;
    margin-right: 12px;
}
.Skills_item_item_table:last-child{
    margin-right: 0;
}
.title_table{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px;
    border-bottom: 1px solid #ABB2BF;
}
.desc_table{
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px;
}
.Skills_item_left{

}
@media screen and (max-width: 800px) {
    .Skills{
        margin-top: 48px;
        padding: 0 12px;
    }
    .Skills_item_left{
        display: none;
    }
    .Skills_item_tables{
        display: flex;
        flex-direction: column;    
        width: -webkit-fill-available;
    }
    .Skills_title_text{
        font-size: 24px;
    }
    .Skills_item_item_table{
        margin-right: 0;
        width: -webkit-fill-available;
    }
    .Skills_item{
        margin-top: 16px;
    }
}