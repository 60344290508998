.SocialLinks{
    position: fixed;
    top: 0;
    left: 24px;
    z-index: 5;
}
.SocialLinks_area{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.SocialLinks_area_line{
    height: 191px;
    width: 1px;
    background: #ABB2BF;
}
.SocialLinks_area_items{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.SocialLinks_area_items_item{
    width: 40px;
}

.SocialLinks_area_items_item svg:hover path{
    fill: #FFA114;
    cursor: pointer;
}

@media screen and (max-width: 700px) {
    .SocialLinks{
        display: none;
    }  
}