.Projects_Items{
    display: flex;
    flex-direction: column;
    margin-bottom: 88px;
}
.Projects_Items_type{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
}
.Projects_Items_type_name{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.Projects_Items_type_view{
    display: flex;
    align-items: center;
    color:  #8AFF14;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    line-height: normal;
}
.Projects_Items_Cards{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
    gap: 12px;
    align-items: start;
    justify-content: space-between;   
    flex-wrap: wrap; 
}
.pinkColor{
    color: #8AFF14;
}

@media screen and (max-width: 800px) {
    .Projects_Items_type_name{
        font-size: 24px;
    }
    .Projects_Items_type{
        margin-bottom: 16px;
    }
    .Projects_Items{
        margin-bottom: 48px;
    }
    .Projects_Items_Cards{
        justify-items: center;
    }
}