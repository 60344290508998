.HomePage{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 800px) {
    .HomePage{
        padding: 0 12px;
        width: -webkit-fill-available;
    }
}