.Card {
    max-width: 440px;
    width: 100%;
    height: 620px;
    border-radius: 16px;
    background: #3F4654;
    transition: 0.3s;
    cursor: pointer;
}

.inCard {
    padding: 24px;
    display: flex;
    height: -webkit-fill-available;
    flex-direction: column;
    justify-content: space-between;
}

.mainCard {
    display: flex;
    flex-direction: column;
}

.titleCard {
    color: #FFF;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.priceCard {
    color: #FFF;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 4px;
    background: #252A34;
    padding: 3px 8px;
    display: inline-flex;
    width: fit-content;
    padding: 3px 8px;
    margin-top: 15px;
    align-items: center;
    display: flex;
}

.descCard {
    display: flex;
    flex-direction: column;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 16px;
    margin-top: 21px;
}

.btnSend {
    display: flex;
    width: -webkit-fill-available;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border: 2px solid #8AFF14;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.btnSend:hover {
    background: #8AFF14;
    color: black;
}

.Card:hover {
    border-radius: 16px;
    background: linear-gradient(153deg, #4C576A 4.98%, #7A8BA8 95.83%);

    transform: translateY(-24px);
}

.mark {
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.pinkColor {
    color: #8AFF14;
}
@media screen and (max-width: 800px) {
    .Card {
        max-width: 440px;
        width: 100%;
        height: auto;
        border-radius: 16px;
        background: #3F4654;
        transition: 0.3s;
        cursor: pointer;
    }

    .titleCard {
        color: #FFF;
        font-variant-numeric: lining-nums tabular-nums;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Fira Code";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .priceCard {
        color: #FFF;
        font-variant-numeric: lining-nums tabular-nums;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Fira Code";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 4px;
        background: #252A34;
        padding: 3px 8px;
        display: inline-flex;
        width: fit-content;
        padding: 3px 8px;
        margin-top: 15px;
        align-items: center;
        display: flex;
    }

    .descCard {
        display: flex;
        flex-direction: column;
        color: #FFF;
        font-family: "Fira Code";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        gap: 16px;
        margin-top: 21px;
        margin-bottom: 40px;
    }

    .btnSend {
        display: flex;
        width: -webkit-fill-available;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border: 2px solid #8AFF14;
        color: #FFF;
        font-family: "Fira Code";
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}