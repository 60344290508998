.About{
    max-width: 1413px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 170px;
    flex-direction: column;
}

.About_Top{
    display: flex;
    align-items: center;
}
.About_Top_Text{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.pinkColor{
    color: #8AFF14;
}
.About_Top_Line{
    height: 1px;
    width: 32vw;   
    background: #8AFF14;
    margin-left: 24px;
}
.About_Cards{
    display: flex;
    padding: 30px 0px;
    justify-content: space-between;
}
.About_Cards_Area{
    display: flex;
    max-width: auto;
    width: 100%;
    flex-direction: column;

    background: #292E37;

}
.About_Cards_Area_ImageCard{
    width: 300px;
    display: flex;

}
.About_Cards_Area img {
    height: 192px;
    padding: 40px 35px 0 35px;
    float: left;
    border-right: 1px solid  #ABB2BF;
    border-bottom: 1px solid  #ABB2BF;
    margin-right: 12px;
}
.About_Cards_Area_Name{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.About_Cards_Area_Stack{
    color: #8AFF14;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.About_Cards_Base_Title{
    display: flex;
    flex-direction: column;
    padding: 12px 30px 12px 12px;
}
.About_Cards_Area_Base_text{
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: auto;
    padding: 0px 30px 0 0;
    border: 1px solid  #ABB2BF;

}
.About_Cards_Image_title{
    display: flex;

}
.About_Cards_Area_p{
    padding-left: 8px;
}

@media screen and (max-width: 800px) {
    .About{
        margin-top: 48px;
    }
    .About_Top_Text{
        font-size: 24px;
    }
    .About_Cards_Area_Base_text{
        padding: 0;
        display: flex;
        flex-direction: column;
    }
    .About_Cards_Area img{
        padding: 0;
        margin-right: 0;
        height: auto;
    }
    .About_Cards {
        display: flex;
        padding: 0;
        justify-content: space-between;
        flex-direction: column;
    }
    .About_Cards_Area_Base_text{
        max-width: -webkit-fill-available;
    }
    .About_Cards_Area{
        max-width: -webkit-fill-available;
        margin-bottom: 24px;
    }
    .About_Top{
        margin-bottom: 16px;
    }
    
}