.Contact {
    max-width: 1416px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 53px;
    margin-bottom: 253px;
}

.Contact_title {
    display: flex;
    align-items: center;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 40px;
}

.Contact_slash {
    color: #8AFF14;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.area_Contacts_Blocks {
    display: flex;
    align-items: inherit;
    justify-content: center;
    width: 100%;

}

.contact_block {
    max-width: 696px;
    width: 100%;
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.contact_text {
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
}

.contact_info_write_us {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #ABB2BF;
    background: #292E37;
}

.in_contact_info_write_us {
    padding: 24px 32px;
}

.titleContactUs {
    color: #FFF;
    font-family: "Fira Code";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 12px;
}

.blocksWriteUser {
    display: flex;
    max-width: 593px;
    width: 100%;
}

.userBlock {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.titleUserSpec {
    color: #8AFF14;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.lineUser {
    display: flex;
    align-items: center;
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.lineUser svg {
    margin-right: 8px;
}

.userBlock:nth-child(2) {
    margin-left: 24px;
}
.socialIcons{
    display: flex;
    justify-content: space-between;
    width: 150px;
    align-items: center;

}

@media screen and (max-width: 1230px) {
    .socialIcons{
        width: 90px;  
    }
    .socialIcons a svg {
        width: 40px;
        height: 40px;
    }
    .Contact_title {
        font-size: 24px;

    }

    .Contact {
        width: -webkit-fill-available;
        padding: 0 12px;
        margin-top: 100px;
    }

    .area_Contacts_Blocks {
        display: flex;
        align-items: inherit;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
    }

    .blocksWriteUser {
        max-width: -webkit-fill-available;
        display: flex;
        flex-direction: column;
    }

    .userBlock:nth-child(2) {
        margin-left: 0;
    }
    .userBlock{
        margin-bottom: 24px;
    }
    .contact_block{
        margin-top: 24px;
    }
    .contact_block{
        max-width: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contact_info_write_us{
        max-width: 570px;
        width: 100%;
    }
}