.SingleProjects{
    display: flex;
    flex-direction: column;
    max-width: 1416px;
    width: 100%;
    margin-top: 53px;
}
.SingleProjects_title {
    display: flex;
    align-items: center;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 40px;
}

.pinkColor {
    color: #8AFF14;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.areaCardsSP{
    display: flex;
    margin-bottom: 75px;
    flex-direction: column;
}
.itemCards{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
    gap: 23px;
}

@media screen and (max-width: 800px) {
    .SingleProjects{
        margin-top: 100px;
        width: -webkit-fill-available;
        padding: 0 12px;
    }
    .SingleProjects_title,.pinkColor{
        font-size: 24px;
    }
    .SingleProjects_title{
        margin-bottom: 16px;
    }
}