.project{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.project_title{
    display: flex;
    max-width: 1413px;
    width: 100%;
}
.project_title_text{
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color:  #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.pinkColor{
    color: #8AFF14;
}
.project_title_line{
    height: 1px;
    width: 42vw;
    background: #8AFF14;
}
.project_title_btnViewAll{
    display: flex;
    align-items: center;
    color: #8AFF14;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}
.Projects_Card{
    margin-top: 40px;
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(339px, 1fr));
    max-width: 1413px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    
}
.Project_Card_title{
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Project_Card_line{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}
.goldColor{
    color: #8AFF14;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Project_Card_line{
    border-bottom: 1px solid  #ABB2BF;
    padding: 8px 8px;
}
.Project_Card_CardItem{
    border: 1px solid #ABB2BF;
}
.Project_Card_CardItem img{
    height: 205px;
}
.Project_Card_Desc_title{
    align-self: stretch;
    color:  #FFF;
    font-family: "Fira Code";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 16px;
}
.Project_Card_Desc_desc{
    color: #ABB2BF;
    align-self: stretch;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 16px 16px 16px ;
}
.Project_Card_Btn_Area{
    display: flex;
    justify-content: space-between;
    padding: 0 16px 16px 16px ;
}
.Project_Card_BtnOpen{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color:  #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    padding: 8px 16px;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #8AFF14;
    cursor: pointer;
}
.Project_Card_BtnOpen:hover{
    background: #8AFF14;
}
.Project_Card_BtnReadMore:hover{
    background: #8AFF14;
}
.Project_Card_BtnReadMore{
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 8px 16px;
    justify-content: center;
    gap: 10px;
    color:  #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 1px solid #8AFF14;
}

@media screen and (max-width: 800px) {
    .project_title_text{
        font-size: 24px;
    }
    .project{
        padding: 0 12px;
        width: -webkit-fill-available;
    }
    .project_title_line{
        display: none;
    }
    .Projects_Card{
        margin-top: 24px;
    }
    .Projects_Card{
        justify-items: center;
        grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
    }
}