
.Contact_form{

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.Contact_form_area{
    padding: 32px;
    display: flex;
    flex-direction: column;
    border: 1px solid  #ABB2BF;
    background: #292E37;
}
.Contact_form_area_title{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.Contact_form_area_mainForm{
    max-width: 504px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.Contact_form_input{
    display: flex;
    max-width: 486px;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
    border: 1px solid  #ABB2BF;
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: rgba(255, 0, 0, 0);
    padding: 8px 8px;
    margin-top: 16px;
}
.Contact_form_area_mainForm_twoInput{
    max-width: 504px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Contact_form_area_mainForm_twoInput input{
    max-width: 244px;
    width: 100%;
}
.Contact_form_area_mainForm_twoInput input:last-child{
   margin-left: 12px;
}
.btn_send_email{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #8AFF14;
    background: #ff14e700;
    cursor: pointer;
    width: max-content;
    margin-top: 16px;
}
.btn_send_email:hover{
    background: #8AFF14;
    color: #292E37;
}

@media screen and (max-width: 800px) {
    .Contact_title{
        font-size: 24px;
        margin-bottom: 24px;
    }
    .Contact_form_area_mainForm{
        max-width: inherit;
    }
    .Contact_form_area{
        padding: 24px 16px;
        width: -webkit-fill-available;
    }
    .Contact_form_area_mainForm_twoInput{
        flex-direction: column;
    }
    .Contact_form_input{
        width: -webkit-fill-available;
        max-width: inherit;
    }
    .Contact_form_area_mainForm_twoInput{
        width: -webkit-fill-available;
        max-width: inherit;
    }
    .Contact_form_area_mainForm_twoInput input{
        max-width: inherit;
        width: -webkit-fill-available;
    }
    .Contact_form_area_mainForm_twoInput input:last-child{
       margin-left: 0;
    }

}