.ProjectPageArea {
    max-width: 1410px;
    width: 100%;
    display: flex;
    margin-top: 76px;
    flex-direction: column;
}

.headerPage {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
}

.pinkColor {
    color: #8AFF14
}

.titleProjectName {
    width: 100%;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ProjectPageDesc {
    margin-top: 12px;
    margin-bottom: 16px;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #ABB2BF;
}

.areaTechno {
    display: flex;
    flex-direction: column;
}

.itemTech {
    display: flex;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.itemTech:last-child{
    margin-top: 12px;
}

.type {
    color: #8AFF14;
    margin-right: 8px;
}

.textType {
    color: #ABB2BF;
}

.contentPage {
    display: flex;
    flex-direction: column;
}

.titlePreview {
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 32px;
}
.mainContent{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.itemContent{
    width: 100%;
    /* border: 1px solid #ABB2BF; */
}
.itemContent img{
    width: 100%;
}
.mainAreaContact{
    display: flex;
    margin-top: 64px;
    flex-direction: column;
    width: 100%;
}

@media screen and (max-width: 800px) {
    .titleProjectName{
        font-size: 24px;
    }
    .headerPage{
        margin-bottom: 48px;
    }
    .titlePreview{
        font-size: 24px;
    }
    .ProjectPageArea{
        padding: 0 12px;
        width: -webkit-fill-available;
    }
}