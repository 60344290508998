.Projects_Items_Cards_item{
    border: 1px solid #ABB2BF;
    max-width: 336px;
    width: 100%;
    margin-bottom: 24px;
    height: 100%;
}
.Projects_Items_Cards_item_img{
    max-width: 336px;
    width: 100%;
    height: 204px;
    overflow: hidden;
    border-bottom: 1px solid #ABB2BF;
}
.Projects_Items_Cards_item_img img {
    max-width: 336px;
    width: 100%;
}
.Projects_Items_Cards_item_title{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ABB2BF;
}
.title_card_desc{
    padding: 8px;
    display: flex;
    flex-direction: column;
}
.goldColor{
    color: #8AFF14;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.lineDesc{
    display: flex;
    align-items: start;
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Projects_Items_Cards_item_desc{
    display: flex;
    flex-direction: column;
}
.title_card_main{
    color:  #FFF;
    font-family: "Fira Code";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
    max-width: 304px;
    overflow: hidden;
}
.title_card_desc_main{
    color: #ABB2BF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-height: 250px;
    height: 100%;
    overflow: hidden;

}
.Projects_Items_Cards_item_desc_area{
    padding: 16px;
}
.Projects_Items_Cards_item_btns{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.Projects_Items_Cards_item_btns_area{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    font-family: "Fira Code";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0px 16px 16px 16px;
}
.btnOpen{
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #8AFF14;
    cursor: pointer;
}
.btnOpen:hover{
    background: #8AFF14;
}
.btnReadMore{
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #8AFF14;
    cursor: pointer;
}
.btnReadMore:hover{
    background: #8AFF14;
    color: black;
}
.textTech{
    word-break: break-all;
    margin-left: 8px;
}