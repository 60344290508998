.Contact{
    margin-top: 111px;
    flex-direction: column;
    max-width: 1413px;
    width: 100%;
}
.Contact_title{
    color: #FFF;
    font-family: "Fira Code";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: start;
    margin-bottom: 40px;
}
.pinkColor{
    color: #8AFF14;
}

@media screen and (max-width: 800px) {
    .Contact {
        margin-top: 24px;
        padding: 0 12px;
        width: -webkit-fill-available;
    }
    .Contact_title{
        font-size: 24px;
    }
    .Contact_form_area_title{
        font-size: 16px;
    }
}